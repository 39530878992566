import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const MachiningTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "userId" },
  { text: "受注番号", value: "productNo" },
  { text: "枝番", value: "branchNumber" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "数量(m)", value: "quantity" },
  { text: "切断数量(m)", value: "cuttingQuantity" },
  { text: "残数量(m)", value: "remainingQuantity" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class MachiningTable {
  workDate: string;
  updateTs: string | null;
  userId: string | null;
  productNo: string | null;
  branchNumber: string | null;
  itemName: string | null;
  size: string | null;
  quantity: number | null;
  cuttingQuantity: string | null;
  remainingQuantity: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    userId: string | null;
    productNo: string | null;
    branchNumber: string | null;
    itemName: string | null;
    size: string | null;
    quantity: number | null;
    cuttingQuantity: string | null;
    remainingQuantity: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.userId = props.userId;
    this.productNo = props.productNo;
    this.branchNumber = props.branchNumber;
    this.itemName = props.itemName;
    this.size = props.size;
    this.quantity = props.quantity;
    this.cuttingQuantity = props.cuttingQuantity;
    this.remainingQuantity = props.remainingQuantity;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const MachiningText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  userId: { text: "作業者", value: "userId" },
  productNo: { text: "受注番号", value: "productNo" },
  branchNumber: { text: "枝番", value: "branchNumber" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  quantity: { text: "数量(m)", value: "quantity" },
  cuttingQuantity: { text: "切断数量(m)", value: "cuttingQuantity" },
  remainingQuantity: { text: "残数量(m)", value: "remainingQuantity" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Machining extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  userId: string | null;
  productNo: string | null;
  branchNumber: string | null;
  itemName: string | null;
  size: string | null;
  quantity: number | null;
  cuttingQuantity: string | null;
  remainingQuantity: number | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Machining);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Machining | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Machining) {
      this.postCode = _props.postCode;
      this.userId = _props.userId;
      this.productNo = _props.productNo;
      this.branchNumber = _props.branchNumber;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.quantity = _props.quantity;
      this.cuttingQuantity = _props.cuttingQuantity;
      this.remainingQuantity = _props.remainingQuantity;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "MACHIN";
    this.userId = null;
    this.productNo = null;
    this.branchNumber = null;
    this.itemName = null;
    this.size = null;
    this.quantity = null;
    this.cuttingQuantity = null;
    this.remainingQuantity = null;
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
